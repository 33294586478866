<template>
  <div class="place-order">
    <el-button type="primary" @click="onOrder()">代客下单</el-button>
    <!-- 编辑弹框 -->
    <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="600px">
      <el-form :model="params" :rules="rules" ref="form-ref" label-width="100px">
        <section class="form-main">
          <el-form-item label="已选门店" prop="shopName">
            {{ params.shopName }}
          </el-form-item>
          <el-form-item label="预约时间" prop="dateToDate">
            <el-date-picker v-model="params.dateToDate" type="datetimerange" range-separator="至"
              start-placeholder="开始日期时间" end-placeholder="结束日期时间" format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-time="[startTime, '23:59:59']">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="预约位置" prop="roomId">
            <el-select v-model="params.roomId" placeholder="请选择位置名称" clearable>
              <el-option v-for="item in roomList" :key="item.roomId" :label="item.roomName" :value="item.roomId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="预约用户" prop="umsMemberId">
            <el-select v-model="params.umsMemberId" filterable clearable placeholder="搜索选择用户" remote
              :remote-method="remoteMethod">
              <el-option v-for="item in userList" :key="item.id" :label="item.nickName + ' | ' + item.mobile"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="线下收款" prop="orderAmount">
            <el-input v-model.trim="params.orderAmount" autocomplete="off" maxlength="6" placeholder="请填写收款金额" clearable
              oninput="value=value.replace(/[^\d.]/g,'')">
              <template slot="prepend">￥</template>
            </el-input>
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
        </section>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { rules } from "@/db/rules";
import { getPlaceUserList, getPlaceOrderAdd } from "@/api/order/order";
export default {
  props: ['roomList'],
  data() {
    return {
      // 编辑弹框
      editShow: false, //弹框开关
      editTitle: "",//弹框标题
      // from表单
      rules,
      params: {
        dateToDate: [],
        shopName: "",
        startTime: "",
        endTime: "",
        roomId: "",
        umsMemberId: "",
        orderAmount: "",
      },
      userList: [],
      selectData: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
    }
  },
  watch: {
    params: {
      deep: true,
      handler(val) {
        if (val.dateToDate.length > 0) {
          this.params.startTime = val.dateToDate[0];
          this.params.endTime = val.dateToDate[1];
        }
      },
    },
  },
  computed: {
    startTime() {
      let now = new Date();
      let h = now.getHours() < 10 ? "0" + now.getHours() : now.getHours();;		// 时
      let m = now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes();;	// 分
      let s = now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds();	// 秒
      return `${h}:${m}:${s}`
    }
  },
  methods: {
    // 【监听】编辑
    onOrder() {
      this.editShow = true;
      this.editTitle = "代客下单";
      let merchantShopInfo = JSON.parse(window.localStorage.getItem('playOne-store-merchantShopInfo'));
      if (merchantShopInfo) {
        Object.assign(this.params, merchantShopInfo);
      }
      this.params.dateToDate = [];
      this.params.startTime = "";
      this.params.endTime = "";
      this.params.roomId = "";
      this.params.umsMemberId = "";
      this.params.orderAmount = "";
    },

    // 【请求】代客下单
    getPlaceOrderAdd() {
      let data = this.params;
      getPlaceOrderAdd(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.editShow = false;
          this.$emit('reload')
        }
      });
    },

    // 【监听】搜索值
    remoteMethod: _.debounce(function (kw) {
      let data = { kw };
      getPlaceUserList(data).then((res) => {
        if (res.isSuccess == "yes") {
          if (res.data) {
            this.userList = res.data;
          } else {
            this.userList = [];
          }
        }
      });
    }, 500),

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getPlaceOrderAdd();
        } else {
          return false;
        }
      });
    },

  }
}
</script>

<style lang="scss" scoped>
.form-footer {
  display: flex;
  justify-content: flex-end;
}
</style>